import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ProcessUpload from './ProcessUpload';


export default function Upload() {
    const [sheetImage, setSheetImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [fileEvent, setFileEvent] = useState(false);
    const [uploadResult, setUploadResult] = useState(null);

    const handleOnFileSelected = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setSheetImage(reader.result);
        };
        reader.readAsDataURL(file);
        setFileEvent(event.target.files);
    }

    const handleFileUpload = async () => {
        setShowModal(true);
        await doUploadFile(fileEvent);
    };

    const doUploadFile = (files) => {
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);

        fetch('https://soundslice-api.mjcueto13.dev/upload', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('File uploaded successfully', data);
                setUploadResult(data);
            })
            .catch(error => {
                console.error('Error uploading file', error);
            });
    };


    return (
        <div className="upload">
            {!sheetImage && (
                <div className="container d-flex flex-column align-items-center justify-content-center">
                    <i className="icon-cloud-upload"></i>

                    <input type="file" accept="image/*" onChange={handleOnFileSelected} placeholder="Choose music sheet to upload" />
                    <p>or drag and drop here</p>
                </div>
            )}

            {sheetImage && (
                <div className="preview d-flex flex-column align-items-center justify-content-center">
                    <h2>Uploaded Sheet Music Preview</h2>
                    <img className="my-2" src={sheetImage} alt="sheet music" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                </div>
            )}

            <ProcessUpload isOpen={showModal} uploadResult={uploadResult} />

            {sheetImage && (
                <div className="p-5">
                    <Button variant="primary" onClick={handleFileUpload}>Convert</Button>
                </div>
            )}
        </div>
    )
}