import React from 'react';
import './App.css';
import logo from './assets/images/logo.png';
import Upload from './components/Upload';

function App() {

  return (
    <div className="App d-flex flex-column align-items-center justify-content-center">
      <img src={logo} className="logo" alt="logo" />
      <Upload />
    </div>
  );
}

export default App;
