import Lottie from 'lottie-react';
import Modal from 'react-bootstrap/Modal';
import noteJson from '../assets/lottie/note.json';

function ProcessUpload({ isOpen, handleClose, uploadResult }) {
    const _handleClose = handleClose || (() => { });
    return (
        <>
            <Modal
                show={isOpen}
                onHide={_handleClose}
                backdrop="static"
                dialogClassName="modal-90w"
                keyboard={false}
            >
                <Modal.Body className={"d-flex flex-column align-items-center " + (uploadResult ? "p-0 m-0" : "p-5")}>
                    {uploadResult === null && (
                        <>
                            <Lottie animationData={noteJson} style={{ height: 500 }} />
                            <h1 className="text-center">Processing your request...</h1>
                        </>
                    )}
                    {uploadResult && (
                        <iframe title="export" src={uploadResult.link + '/embed/'} width="100%" height="500" allowFullScreen frameBorder={0} />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProcessUpload;